.login-container {
    display: flex;
    height: 100vh;
    position: relative;
  }
  
  .login-left {
    flex: 1;
    display: flex;
    /* width: 50%; */
    justify-content: flex-start;
    align-items: center;
  }
  
  .login-left img {
    width: 100%;
    height: 100vh;
    max-width: 544px; 
  }
  
  .login-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 180px; 
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    margin-bottom: 0.5rem;
  }
  
  .logininput {
    padding: 0.5rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

 
  .logo {
    width: 95px;
    height: 19px;
    margin-bottom: 16px;
    align-items: left
    ;
  }
.loginheading
{
color:  #1C2024;
font-family: "Inter-Medium", Helvetica;
font-size: 28px;
font-style: normal;
font-weight: 500;
line-height: 43px;
align-self: stretch;
  }
.loginpannel {
width: 336px;
text-align: initial;
}
.subheading
{
color:  rgba(7, 6, 0, 0.58);
font-family: "Inter-Medium", Helvetica;
font-size: 14.7px;
font-style: normal;
font-weight: 400;
line-height: 21px;
margin-bottom: 56px
}
.form-group label {
color:  rgba(7, 6, 0, 0.58);
font-size: 14px;
font-family: "Inter-Medium", Helvetica;

font-style: normal;
font-weight: 500;
line-height: 20px;
}
.loginbutton
{
display: flex;
width: 336px;
height: 40px;
padding: 0px 16px;
justify-content: center;
align-items: center;
gap: 12px;
color: #fff;
cursor: pointer;
font-family: "Inter-Medium", Helvetica;
font-style: normal;
}

.error-message {
    color:  #E5484D;
    font-family: "Inter-Medium", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .password-input-container {
    position: relative;
  }
  
  .password-toggle-icon-container {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust this value as needed */
    transform: translateY(-50%);
    cursor: pointer;
  }
  .password-label-container {
    display: flex;
    align-items: center;
  }
  
  .forget-password {
    margin-left: auto;
  }
  .forget-password:hover {
    color: black;
  }
.backtologin
{
display: flex;
padding: 4px;
justify-content: center;
align-items: center;
gap: 8px;
color: #1C2024;
/* font-feature-settings: 'salt' on; */
font-size: 12px;
font-style: normal;
font-weight: 500;
font-family: "Inter-Medium", Helvetica;
}
.subheading2
{
color:  rgba(7, 6, 0, 0.58);
font-family: "Inter-Medium", Helvetica;
font-size: 14.7px;
font-style: normal;
font-weight: 400;
line-height: 21px;
}