.selectmodule
{
  display: flex;
  width: 332px;
  padding: 16px;
  align-items: center;
  gap: 12px;
  position: absolute;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(7, 6, 0, 0.58);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 6px;
  background: #E5ECF0; 
  z-index: 2;

}
.analytics-button {
    align-items: center;
    border: 1px solid  rgba(26, 26, 0, 0.16);
    border-radius: 6px;
    display: flex;
    gap: 10px;
    padding: 8px 14px;
    position: relative;
    width: 310px;
    margin: 10px 0;
    color: #1c2024;
    font-family: "Inter-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 0;
    letter-spacing: -0.04px;
    line-height: 26px;
    top: 0;
    background:none;
    margin-bottom: 16px;
    background-color: transparent;
    color: black;
    padding: 8px 16px;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .risk-button {
    align-items: center;
    border: 1px solid  rgba(26, 26, 0, 0.16);
    border-radius: 6px;
    display: flex;
    gap: 10px;
    padding: 8px 14px;
    position: relative;
    width: 310px;
    margin: 10px 0;
    color: #1c2024;
    font-family: "Inter-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 0;
    letter-spacing: -0.04px;
    line-height: 26px;
    top: 0;
    background:none;
    margin-bottom: 16px;
    background-color: transparent;
    color: black;
    padding: 8px 16px;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .analytics-button:hover {
    background-color: rgba(255, 108, 3, 0.09);
    color: rgba(188, 72, 0, 0.98);
    border: 1px solid  rgba(255, 108, 3, 0.09);
  }
  .risk-button:hover {
    background-color: rgba(255, 108, 3, 0.09);
    color: rgba(188, 72, 0, 0.98);
    border: 1px solid  rgba(255, 108, 3, 0.09);
  }
  .analytics-button:hover img {
    content: url('/public/compareselected.png');
  }
  
.dropdownlabel
{
color: var(--tokens-colours-text-default, #1C2024);
font-family: "Inter-Medium", Helvetica;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px;
text-align: left;
margin-bottom: 1vh;
}
.dropdown-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .compare-pannel {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 340px;
    margin-top: 40px;
    margin-left: 70px;
    position: fixed;
    background-color: white; 
    z-index: 2; 
    padding: 24px 16px;
    border-right: 1px solid rgba(26, 26, 0, 0.16);
  }
.compareecondarytext
{
  color: rgba(7, 6, 0, 0.58);
font-family: "Inter-Medium", Helvetica;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px;
text-align: initial;
margin-bottom: 18px;
}
.download-container{
  margin-bottom: 2vh;
}
.compared-button-container {
  display: flex;
  width: 311px;
  padding: 16px 0px;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  position: absolute;
}

.comparedataset-button:disabled {
  background-color: rgba(20, 20, 0, 0.05);
  color:  rgba(30, 25, 1, 0.24); 
}

.comparedataset-button:not(:disabled) {
  background-color: rgba(4, 4, 0, 0.91); 
}
.comparedataset-button {
  width: 311px;
  border-radius: 4px;
  background: rgba(4, 4, 0, 0.91);
  color: #ffffff; 
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.resetbutton
{margin-top: 10px;
color:  #1C2024;
font-feature-settings: 'salt' on;
font-family: "Inter-Medium", Helvetica;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.evalutionbuttons
{padding: 4px 8px;
  height: 32px;
justify-content: center;
align-items: center;
gap: 2px;
border: none;
background-color: white;
align-self: stretch;
color: var(--tokens-colours-text-default, #1C2024);
font-family: "Inter-Medium", Helvetica;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 160%;
}
.bottomtext
{
color:  rgba(9, 9, 0, 0.49);
font-family: "Inter-Medium", Helvetica;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 160%; 
margin-top: 20px;
}
.compare-button-container
{
  bottom: 0px;
}