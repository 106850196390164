.pricing-analytics {
    display: flex;
    align-items: center;
  }
  
  .headings {
    margin-right: 16px;
  }
  
  .headings h3 {
    margin: 0 0 8px 0;
  }
  
  .divs {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  
  .styled-div-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .styled-div-content > div {
    flex-grow: 1;
  }
  .styled-div {
    width: 232px;
    height: 69px;
    display: flex;
    align-items: left;
    border-radius: 8px;
    border: 1px solid rgba(237, 219, 249, 1);
    padding: 12px 16px;
    background-color: rgba(253, 250, 255, 1);
  }
  .styled-icon {
    color: rgba(142, 78, 198, 1);
    background-color: rgba(249, 241, 254, 1);
    border-radius: 50%;
  
    font-size: 24px;
    margin-left: auto;
  }
.MuiBox-root css-zrlv9q {
    display: flex;
    justify-content: flex-start;
  }
  .stepcontainer {
    display: flex;
    padding: 10px;
    border: 1px solid rgba(26, 26, 0, 0.16);
    width: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }
  
  .styled-step {
    cursor: pointer;
    border-radius: 6px;

  }
  
  .styled-step-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;

  }
  
  .active-step {
    background-color: rgba(237, 219, 249, 1);
    font-weight: bold;
    text-align: center;
    align-items: center;
    color: rgba(142, 78, 198, 1);
  }
  
  .completed-step .stepNumber {
    background-color: white;
    border: none;
  }
  
  .step {
    text-align: center;
  }
  
  .stepNumber {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: white;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    margin-left: 8px;
  }
  .active-step .stepNumber,
.completed-step .stepNumber {
  background-color: rgba(237, 219, 249, 1);
  border: 1px solid rgba(142, 78, 198, 1);

}
  