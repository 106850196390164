.label {
  color: #1c2024;
  font-family: "Inter-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  /* position: fixed; */
  top: 0;
}
.menutext
{
color: #1c2024;
font-family: "Inter-Medium", Helvetica;
font-size: 10px;
font-weight: 500;
left: 0;
letter-spacing: 0.5px;
line-height: 16px;
/* position: fixed; */
text-align: center;
}
.menuicon{
border-radius: 3.5px;
height: 36px;
margin-top:5;
overflow: hidden;
align-items:center;
/* position: relative; */
/* width: 36px; */
}
.exploretext
{
color: #21201C;
font-family: "Inter-Medium", Helvetica;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 26px; /* 162.5% */
letter-spacing: -0.04px;
margin-bottom: 12px;
text-align: initial;
display: flex;
}
.exploresecondarytext
{
color: rgba(7, 6, 0, 0.58);
font-family: "Inter-Medium", Helvetica;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px;
text-align: initial;
margin-bottom: 24px;
}
.nav {
  display: block;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-family: "Inter-Medium", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.2px;
  width: 80px; 

}

.nav-links {
  display: flex;
  align-items: center;
  font-family: "Inter-Medium", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.2px;
 /* Set the width */
}
.explore-button {
  align-items: center;
  border: 1px solid  rgba(26, 26, 0, 0.16);
  border-radius: 6px;
  display: flex;
  gap: 10px;
  padding: 8px 14px;
  position: relative;
  width: 310px;
  margin: 10px 0;
  color: #1c2024;
  font-family: "Inter-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 0;
  letter-spacing: -0.04px;
  line-height: 26px;
  top: 0;
  background:none;
  margin-bottom: 16px;
}

@media (min-width: 769px) {
  .sidebar-sticky {
    position: fixed;
    top:1px;
    height: 100vh;
    width: 68px;
    z-index: 1000;
    /* padding: 18px var(--Space-2, 8px); */
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid #F2F2F0;
    background-color: #FDFDFC;
    flex-direction: column;
  }
}
.explore-container {
  position: fixed;
  position: fixed;
  background-color: white; 
  z-index:1; 
  border-right: 1px solid rgba(26, 26, 0, 0.16);
  
}

.menuContent
 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 355px;
  margin-top: 40px;
  margin-left: 70px;
  position: fixed;
  background-color: white; 
  z-index: 1; 
  padding: 24px 16px;
  border-right: 1px solid rgba(26, 26, 0, 0.16);
}
.administrative-boundary

 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 355px;
  margin-top: 40px;
  margin-left: 70px;
  position: fixed;
  background-color: white; 
  z-index: 2; 
  padding: 24px 16px;
  border-right: 1px solid rgba(26, 26, 0, 0.16);
}
.visualise-boundary {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 355px;
  margin-top: 40px;
  margin-left: 70px;
  position: fixed;
  background-color: white; 
  z-index: 3; 
  padding: 24px 16px;
  border-right: 1px solid rgba(26, 26, 0, 0.16);
}
.custom-select{
font-size: 14px;
text-align: left;

}
.dropdown-container{
  margin-bottom: 2vh;
}
.map-drawer-container {
  position: fixed;
  right: 0;
  display: flex;
  align-items: flex-start; 
  z-index: 4;/* Align items to the start of the container */
}

.icon {
  cursor: pointer;
  background-color: white; /* Add your desired background color */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  transition: margin-right 0.3s ease-in-out; /* Add transition to the margin */
}

.drawer-content {
  width:30vw; 
  color: white; 
  background: white;
  transition: transform 0.3s ease-in-out; /* Add transition to transform only */
  transform: translateX(100%);
  border: '1px solid black';
  height: 100vh;
}
.drawer-content.expanded {
  transform: translateX(0);
z-index: 4;
  width: 70.4vw; 
}
.drawer-content.visible {
  transform: translateX(0); /* Move the drawer to the visible position */
}

.map-drawer-container:not(.shifted) .icon {
  margin-right: -30vw;
}
.maplayerspannel {
  padding: 16px;
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: calc(100vh - 20px); /* Adjust max-height as needed */
}

.exploredataset-button-container {
  display: flex;
  width: 330px;
  padding: 16px 0px;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  position: absolute;
  bottom: 50px;
}

.exploredataset-button:disabled,
.button:disabled {
  background-color: rgba(20, 20, 0, 0.05);
  color: rgba(30, 25, 1, 0.24);
}

.exploredataset-button:not(:disabled) {
  background-color: rgba(4, 4, 0, 0.91); 
}
.exploredataset-button,
.button  {
  width: 310px;
  border-radius: 4px;
  background: rgba(4, 4, 0, 0.91);
  color: #ffffff; 
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.selectedloc-button
{
display: flex;
width: 310px;
padding: 16px;
align-items: center;
justify-content: space-between;
gap: 12px;
border: 1px solid  rgba(1, 97, 158, 0.07);
border-radius: 6px;
background: rgba(1, 97, 158, 0.07);
font-family: "Inter-Medium", Helvetica;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
}

.selectedloc-buttonvisual
{
display: flex;
width: 310px;
padding: 16px;
align-items: center;
gap: 12px;
font-family: "Inter-Medium", Helvetica;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
border: 1px solid  rgba(1, 97, 158, 0.07);
border-radius: 6px;
background: rgba(1, 97, 158, 0.07);
}
.selectedlocdrawer-button
{
display: flex;
width: 488px;
height: 56px;
padding: 16px;
align-items: center;
gap: 12px;
border: 1px solid  rgba(1, 97, 158, 0.07);
border-radius: 6px;
background: rgba(1, 97, 158, 0.07);
font-family: "Inter-Medium", Helvetica;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
}
.selectedloctext
{
color: rgba(7, 6, 0, 0.58);
font-family: "Inter-Medium", Helvetica;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
text-align: initial;
margin-bottom: 16px;
}
.horizontal-rule {
  border: 1px solid rgba(26, 26, 1, 0.12); 
  margin: 16px 0; 
}
.datasetstext
{
color:  #1C2024;
font-family: "Inter-Medium", Helvetica;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px; 
text-align: initial;
margin-bottom: 24px

}
.top-icons-container {
  position: absolute;
  top: 10px; /* Adjust the top position as needed */
  right: 10px; /* Adjust the right position as needed */
  display: flex;
}
.downloadselect
{
  height: 2vh;
  width: 140px;
  margin-bottom: 1vh;
  padding: 0px 10px;
  border-radius: 8px;
border-color:'rgba(26, 26, 0, 0.16)';
font-family: "Inter-Medium", Helvetica;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 12px;
background: var(--tokens-colours-surface-default, rgba(255, 255, 255, 0.90)); 

}

.top-icon {
  margin-left: 10px; /* Add some margin between the icons */
}
.apptraytext
{
color:  #1C2024;
text-align: center;
font-family: "Inter-Medium", Helvetica;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 160% */
letter-spacing: 0.5px;
}
.download-pannel

{
  position: relative;
  display: flex;
  width: 100%;
  padding: 32px 50px 32px 100px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--Space-2, 8px);
  background: white;
  border: 1px solid rgba(26, 26, 0, 0.16);

}
.download-pannel2
{
position: relative;
display: flex;
flex-direction: column;
padding: 12px 24px 20px 95px;
align-items: flex-start;
gap: 12px;
width: 78vw;
flex-shrink: 0;
/* background: #F2F2F0; */
}

.inputArea {
  border: 1px solid rgba(26, 26, 0, 0.16);
  width: 100%;
  height: 36px;
  font-size: 14px;
  border-radius: 8px;
  padding: 10px;
}

.card {
  display: flex;
  width: 100%;
  padding: 12px;
  flex-direction: 'column';
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background: var(--tokens-colours-surface-solid, #FFF);
  box-shadow: 0px 4px 11px 0px var(--neutral-colours-neutral-alpha-4, rgba(28, 28, 2, 0.08));
}
.search-bar {
  position: relative;
}

.search-bar input[type="text"] {
  padding-left: 30px; /* Adjust as needed to position the text properly */
}

.search-bar i {
  position: absolute;
  left: 10px; /* Adjust as needed to position the icon properly */
  top: 50%;
  transform: translateY(-50%);
}
.list {
font-family: "Inter-Medium", Helvetica;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
padding:  8px 12px ;
margin-top: 10px;
margin-right: 28px;
align-items: flex-start;
color: #1C2024;

gap:  8px;
width: 298px;
padding-left: 0px;
border-radius: 8px;
height: 176px;
overflow: scroll;
border: 1px solid var(--tokens-colours-border-default, rgba(26, 26, 0, 0.16));
background: var(--tokens-colours-surface-solid, #FFF);
}
.title
{
  color: #1C2024;
font-size: 17px;
font-weight: '700';
line-height: 26px; /* 185.714% */
letter-spacing: -0.04px;
font-weight:500;

}
.datasetgraph
{
color: #101010;
font-family: "Inter-Medium", Helvetica;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 145%; 
}
.icononmap:hover {
  background-color: #FFEDD5;
  align-items: center;
}
.selectedboundarypannel3div{
  display: flex;
  width: 20vw;
  height: 14vh;
  padding: 16px;
  overflow-y: scroll; 
  flex-direction: column;
  align-items: flex-start;
background: #F9F9F8;

}
.selectedboundarypannel3{
display: flex; 
 height: 20px;
padding:4px; 
justify-content: center;
align-items: center;
align-content: center;
gap: 4px;
flex-wrap: wrap; 
border-radius: 4px;
border: 1px solid var(--tokens-colours-border-partially-selected, #D3D2CE);
background: var(--tokens-colours-surface-grey-chip, #F9F9F8);

}
input[type="checkbox"]:checked {
  /* Change the checked color to orange */
  background-color: orange;
  border-color: orange; /* Add this line if you want to change the border color as well */
}
.iconbox
{
display: flex;
width: 36px;
height: 36px;
padding: 6px;
justify-content: center;
align-items: center;
border-radius: 3.5px;
background:  #FFF;
margin-right:5px;
box-shadow: 0px 4px 6px 0px  rgba(28, 28, 2, 0.08);
}
.zoomingicon
{
  display: flex;
width: 81px;
height: 36px;
padding: 6px 7.933px 6px 8px;
justify-content: center;
align-items: center;
border-radius: 3.5px;
background:  #FFF;
margin-right:5px;
box-shadow: 0px 4px 6px 0px  rgba(28, 28, 2, 0.08);
}
.show-dataset
{
  display: flex;
width: 20vw;
height: 30px;
padding: 0px 16px;
justify-content: center;
align-items: center;
gap: 12px;
border-radius: 4px;
border: 1px solid var(--colours-sand-8, #BCBBB5);
opacity: 0.88;
background: var(--colours-sand-2, #F9F9F8);
color: var(--tokens-colours-text-default, #1C2024);
font-family: "Inter-Medium", Helvetica;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
margin-top: 10px;
}
.download-dataset
{
display: flex;
width: 20vw;
height: 30px;
padding: 0px 16px;
justify-content: center;
align-items: center;
gap: 12px;
border-radius: 4px;
margin-top: 8px;
background: var(--neutral-colours-neutral-alpha-12, rgba(4, 4, 0, 0.91));
color: var(--Tokens-Colors-contrast-accent, #FFF);
font-family: "Inter-Medium", Helvetica;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; 
}
.list-item{
  font-family: "Inter-Medium", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
}
.list-item:nth-child(even) {
  background-color: rgba(41, 41, 5, 0.03); /* Light grey background for even rows */
}
.checkbox.Mui-checked {
  color: #f76808; /* Orange color for checked checkboxes */
}
.download-dataset:disabled {
  background-color: rgba(20, 20, 0, 0.05); /* Grey background color */
  color: rgba(30, 25, 1, 0.24); /* Grey text color */
  pointer-events: none;
  border: none; /* Disable pointer events */
}
.downloadselect:disabled {
  background-color: rgba(20, 20, 0, 0.05); /* Grey background color */
  color: rgba(30, 25, 1, 0.24); /* Grey text color */
  pointer-events: none;
  border: none; /* Disable pointer events */
}
.show-dataset:disabled {
  background-color: rgba(20, 20, 0, 0.05); /* Grey background color */
  color: rgba(30, 25, 1, 0.24); /* Grey text color */
  pointer-events: none;
  border: none; /* Disable pointer events */
}
.explore-button:hover {
  background-color: rgba(255, 108, 3, 0.09);
  color: rgba(188, 72, 0, 0.98);
  border: 1px solid  rgba(255, 108, 3, 0.09);
}

.searchResult {
  list-style: none;
  text-align: left;
  padding: 0;
  max-height: 40vh;
  overflow: scroll;
}
.searchResult li {
  cursor: pointer;
  border: 1px solid #eee;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 8px;
  font-size: 14px;
  background-color: #fff;
  transition: all ease-in-out 0.3s;
}

.searchResult li:hover {
  background-color: #eee;
}
.drawTools {
  display: flex;
  max-width: 310px;
  margin-bottom: 20px;
  gap: 1px;
  background: #ccc;
}
.drawTools button {
  width: 100%;
  border: none;
  padding: 10px;
  cursor: pointer;
}
.drawTools button:hover,
.drawTools button.active {
  background-color: #f76808;
  color: #fff;
}
.clearBtn {
  background: transparent;
  border: none;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
.button-transition {
  transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
}
.dropzone {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  cursor: pointer;
  border: 2px dashed #ccc;
  height: 200px;
  justify-content: center;
  border-radius: 8px;
  background-color: #eee;
}
.changeBaseMap {
  position: absolute;
  bottom: 50px;
  left: 10px;
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(16, 16, 16, 0.3);
  overflow: hidden;
  padding: 2px;
  border: none;
  z-index: 10;
}
.changeBaseMap img {
  width: 100%;
  border-radius: 8px;
}

.legend {
  text-align: left;
  list-style: none;
  padding: 0;
  margin: 0;
}
.legend li {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 12px;
}
.legend li span {
  width: 12px;
  height: 12px;
  display: block;
  border: 1px solid #ccc;
}

