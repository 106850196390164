.container {
    display: flex;
    width: 95%;
    margin-left: 70px;
    padding-top: 2vh;
  }
  
  .leftColumn {
    width: 40%;
    padding: 10px;
    height: 100vh;
    /* border-right: 1px solid #ccc; */
    transition: width 0.3s ease, padding 0.3s ease;
    overflow-y: scroll;
  }
  
  .rightColumn {
    width: 60%;
    padding: 20px;
    height: auto;
    transition: width 0.3s ease;
    border-left: 1px solid #ccc;

  }
  
  .icon {
    margin-bottom: 20px;
  }
  
  .leftColumn button {
    display: block;
    margin-bottom: 10px;
  }
  
  .collapsed .leftColumn {
    width: 50px;
    height: 35px;
    color: orange;
    border-bottom:1px solid #ccc ;
    border-top: 1px solid #ccc;
    margin-top: 10px;
    overflow: hidden;
  }
  
  .collapsed .rightColumn {
    width: 90%;
  }
  
  .collapsed .leftColumn .exploretext,
  .collapsed .leftColumn .compareecondarytext,
  .collapsed .leftColumn button {
    display: none;
  }
  
.maintext
{
font-weight: 500;
font-size: 12px;
line-height: 20px;
letter-spacing: 0.04px;
}
.value
{
font-weight: 700;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.04px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
  margin-top: -100px; /* Move the loading container upwards */
}

.loading-dots {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FFCCA7; /* Default color for dot 2 and 3 */
  animation: blink 1.4s infinite both;
}

.dot1 {
  background-color: #BD4B00;
}

.dot2 {
  animation-delay: 0.2s;
}

.dot3 {
  animation-delay: 0.4s;
}

@keyframes blink {
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.loading-text {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.loading-subtext {
  margin-top: 10px;
  font-size: 14px;
}